<template>
  <transition name="filter-fade">
    <div class="filter-label">
      <slot name="label-parts"> </slot>
      <icon icon="times-regular" size="s" @click="removeFilter()"></icon>
    </div>
  </transition>
</template>

<script>
import Icon from "vue-elements/icon/Icon.vue";
export default {
  components: { Icon },
  methods: {
    removeFilter() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/scss/_helpers/background' as background;
@use 'assets/scss/_helpers/typography' as typography;
@use 'assets/scss/_helpers/border' as border;
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_variables/colors';

.filter-label {
  @include typography.font((14px, 14px));
  @include icon.color(colors.$link-base-default);

  border-radius: 10px;
  background-color: colors.$element-grey-20;
  color: colors.$link-base-default;
  border: 1px solid colors.$border-base;

  display: inline-flex;
  padding: 8px 12px;
  align-items: center;
  margin-right: 8px;
  gap: 4px;

  .value {
    font-weight: 700;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.filter-fade-enter-active {
  transition: all 0.3s ease;
}
.filter-fade-leave-active {
  transition: all 0.3s ease-out;
}
.filter-fade-enter {
  transform: translateX(15px);
  opacity: 0;
}


.filter-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
