<template>
  <panel
    :panel="panel"
    :slug="panel.slug"
    :value="value"
    :comparer-name="analyticsName"
  >
    <template #panelTitle />

    <template #panelInput>
      <div
        v-for="option in panel.options"
        :key="panel.slug"
      >
        <BigRadioInput
          v-model="checked"
          :title="option.label"
          :name="option.value"
        />
      </div>
    </template>
  </panel>
</template>

<script>

import BigRadioInput from 'vue-elements/input/BigRadioInput.vue';
import Panel from './Panel.vue';

export default {
    components: {
        BigRadioInput,
        Panel,
    },
    props: ['panel', 'value', 'analyticsName'],
    emits: ['value'],
    data() {
        return {
            checked: this.value,
        };
    },
    watch: {
        checked: {
            handler(checked) {
                this.$emit('value', {
                    slug: this.panel.slug,
                    value: checked,
                });
            },
        },
        value: {
            handler(value) {
                this.checked = value;
            },
        },
    },
};
</script>
<style lang="scss">
@use '../styles/helpers' as *;

.panel.SmallRadio {
  .radio {
    @include radio-field();
  }
}
</style>
