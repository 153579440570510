<template>
  <panel :panel="panel" :slug="panel.slug" :checked="checked" :comparer-name="analyticsName">
    <template #panelTitle> </template>

    <template #panelInput>
      <section>
        <input type="checkbox" v-model="checked" :name="panel.slug"  />
      </section>
    </template>
  </panel>
</template>

<style lang="scss">
</style>

<script>
import Panel from "./Panel.vue";

export default {
  components: { Panel },
  props: ["panel", "value","analyticsName"],
  data: function () {
    return {
      checked: this.value,
    };
  },
  watch: {
    checked: {
      handler(checked) {
        this.$emit("value",  { slug: this.panel.slug, value: checked});
      },
    },
    value: {
      handler(value) {
        this.checked = value;
      },
    },
  },
};
</script>
