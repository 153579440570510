<template>
  <transition name="active-filters">
    <div class="active-filters" v-if="showFilters">
      <div class="heading">
        <span class="title"> Jouw gekozen filters </span>
        <span class="remove" @click="clearFilters()" role="button">
          wissen
          <icon icon="x-fa-trash-alt" size="sm"></icon>
        </span>
      </div>
      <!-- Km -->
      <filter-label @remove="removeFilter('km')" v-if="customSelection['km']">
        <template #label-parts>
          <transition name="value-update">
            <span class="value" :key="customSelection['km']">{{
              customSelection["km"]
            }}</span>
          </transition>

          <span class="label"> km per jaar</span>
        </template>
      </filter-label>
      <!-- Contract Duration -->
      <filter-label
        @remove="removeFilter('contract_duration')"
        v-if="customSelection['contract_duration']"
      >
        <template #label-parts>
          <transition name="value-update">
            <span class="value" :key="customSelection['contract_duration']">
              {{ customSelection["contract_duration"] }} maanden</span
            >
          </transition>
          <span class="label"> contractduur</span>
        </template>
      </filter-label>
      <!-- Price range -->
      <filter-label
        @remove="removeFilter('min_price'), removeFilter('max_price')"
        v-if="customSelection['min_price'] && customSelection['max_price']"
      >
        <template #label-parts>
          <span class="label">Van </span>
          <transition name="value-update">
            <span class="value" :key="customSelection['min_price']">{{
              toCurrencyShortEur(customSelection["min_price"])
            }}</span>
          </transition>
          <span class="label"> tot </span>
          <transition name="value-update">
            <span class="value" :key="customSelection['max_price']">{{
              toCurrencyShortEur(customSelection["max_price"])
            }}</span>
          </transition>
          <span class="label"> per maand </span>
        </template>
      </filter-label>
      <!-- Only min price -->
      <filter-label
        @remove="removeFilter('min_price')"
        v-else-if="customSelection['min_price']"
      >
        <template #label-parts>
          <span class="label">Vanaf </span>
          <transition name="value-update">
            <span class="value" :key="customSelection['min_price']">{{
              toCurrencyShortEur(customSelection["min_price"])
            }}</span>
          </transition>
          <span class="label"> per maand </span>
        </template>
      </filter-label>
      <!-- Only max price -->
      <filter-label
        @remove="removeFilter('max_price')"
        v-else-if="customSelection['max_price']"
      >
        <template #label-parts>
          <span class="label">Tot </span>
          <transition name="value-update">
            <span class="value" :key="customSelection['max_price']">{{
              toCurrencyShortEur(customSelection["max_price"])
            }}</span>
          </transition>
          <span class="label"> per maand </span>
        </template>
      </filter-label>
      <!-- Fuel type -->
      <filter-label
        @remove="removeFilter('fuel_type')"
        v-if="customSelection['fuel_type']"
      >
        <template #label-parts>
          <transition name="value-update">
            <span class="value" :key="customSelection['fuel_type']">{{
              customSelection["fuel_type"]
            }}</span>
          </transition>
        </template>
      </filter-label>
      <!-- Km -->
      <filter-label
        @remove="removeFilter('car_maker')"
        v-if="customSelection['car_maker']"
      >
        <template #label-parts>
          <span class="label"> Automerk </span>
          <transition name="value-update">
            <span class="value" :key="customSelection['car_maker']">{{
              customSelection["car_maker"]
            }}</span>
          </transition>
        </template>
      </filter-label>

      <filter-label
          @remove="removeFilter('used_cars')"
          v-if="customSelection['used_cars']"
      >
        <template #label-parts>
          <span class="label"> Toon </span>
          <transition name="value-update">
            <span class="value" :key="customSelection['used_cars']">{{
                customSelection["used_cars"]
              }}</span>
          </transition>
        </template>
      </filter-label>

      <hr class="divider" />
    </div>
  </transition>
</template>

<script>
import Icon from "vue-elements/icon/Icon.vue";
import FilterLabel from "./FilterLabel.vue";
export default {
  props: ["customSelection"],
  components: { Icon, FilterLabel },
  methods: {
    removeFilter(slug) {
      this.$emit("removeSelectedFilter", slug);
    },
    clearFilters() {
      this.$emit("clearSelectedFilters");
    },
  },
  computed: {
    showFilters: function () {
      return !Object.values(this.customSelection).every(
        (x) => x === null || x === ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/scss/_helpers/display' as display;
@use 'assets/scss/_helpers/typography' as typography;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/typographies' as typographies;
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_variables/colors' as colors;

.active-filters {
  .heading {
    @include display.flex-row-between();
    @include typography.font(typographies.$headings, h5);
  }

  .remove {
    @include display.flex-row-center;
    @include icon.color(colors.$link-base-default);
    @include typography.font((14px, 100%));

    color: colors.$link-base-default;
    .icon-element {
      margin-left: 4px;
    }
  }

  .divider {
    @include breakpoint.up(md) {
      display: revert;
    }
    display: none;
    height: 3px;
    background-color: white;
    opacity: 1;
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: 8px;
    margin-top: 32px;
  }
}

.active-filters-enter-active {
  transition: all 0.3s cubic-bezier(0.38, 0.02, 0.52, 1.56);

  .filter-fade-enter-active {
    transition-delay: 0.3s;
  }
}

.active-filters-leave-active {
  transition: all 0.3s cubic-bezier(0.38, 0.02, 0.52, 1.56);
}

.active-filters-enter,
.active-filters-leave-to {
  transform: translateY(-35px);
  opacity: 0;
}
</style>
