<template>
  <div
    class="panel"
    :class="panel.type"
  >
    <slot name="panelTitle">
      <div
        v-if="panel.title"
        class="panel-title"
      >
        <span class="title">{{ panel.title }}</span>
        <Tooltip
          v-if="panel.tooltip"
          :text="panel.tooltip"
        />
      </div>
    </slot>
    <slot name="panelInput">
      <!-- To be filled with the input(s) of this panel -->
    </slot>
  </div>
</template>

<script>
import Tooltip from '../../../../abstracts/elements/tooltip/tooltip.vue';


export default {
    props: ['panel', 'value', 'analyticsName'],
    components: {
        Tooltip,
    },
    data() {
        return {
            tooltipExpanded: false,
        };
    },
};
</script>

<style lang="scss">
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/colors' as colors;
@use 'assets/scss/_helpers/typography'as typography;
@use 'assets/scss/_variables/typographies'as typographies;

.panel {
  .panel-title {
    padding: 12px 0;
    position: relative;
  }

  .hover-area {
    display: inline-flex;
  }

  .title {
    @include typography.font(typographies.$headings, h5);
  }
}
</style>
