<template>
  <div
    id="flamingoProductDisplay"
    class="flamingo-product-display flamingo-car-model-display "
    :data-productId-id="productId"
  >
    <section :class="{ 'is-loading': loading }">
      <topbar
        type="desktop"
        :panels="panels"
        :selection="filters"
        :comparer-name="comparerName"
        @filter="updateFilter($event)"
      />
      <topbar
        type="mobile"
        :panels="mobilePanels"
        :selection="filters"
        :comparer-name="comparerName"
        @filter="updateFilter($event)"
      />
      <table
        v-if="!showEmptyView"
        class="flamingo-display-table"
      >
        <thead>
          <tr>
            <th>Aanbieder</th>
            <th>Model</th>
            <th>Voorwaarden</th>
            <th>Eigen risico</th>
            <th>Maandprijs</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <row-product-card
            v-for="(productOffer, key) in formattedProductOffers"
            :key="key"
            :index="key"
            :product="productOffer"
            :filters="filters"
            :deviates-from-filter-km="deviatesFromFilterKm(productOffer)"
            :deviates-from-filter-contract-duration="deviatesFromFilterContractDuration(productOffer)"
            @productClick="handleProductClick($event)"
          />
        </tbody>
      </table>
      <empty-view
        v-if="showEmptyView"
        :key="-2"
        icon="x-fa-car-crash"
        @reset="resetFilters()"
      >
        <template #heading>
          Geen private lease auto's gevonden
        </template>
        <template #message>
          Momenteel zijn er geen contracten voor deze auto met de door jouw
          opgegeven filters. Kies voor 'terug' om de vorige selectie terug te
          zetten of chat met een van onze collega's om op zoek te gaan naar de
          beste deal voor jou!
        </template>
      </empty-view>
    </section>
  </div>
</template>
<script>
import EmptyView from 'vue-elements/product-card/EmptyView.vue';
import Topbar from './components/Topbar.vue';
import RowProductCard from './components/RowProductCard.vue';

export default {
    components: {
        Topbar,
        RowProductCard,
        EmptyView,
    },
    props: {
        productId: null,
        config: Object,
    },
    data() {
        return {
            filters: {
                model: null,
                km: '10000',
                contract_duration: '60',
            },
            initialFilters: {},
            defaultSelection: {},
            productOffers: this.config ? this.config.offers : [],
            loading: false,
            apiError: false,
            comparerName: null,
            panels: [
                {
                    slug: 'km',
                    type: 'InlineRadio',
                    title: 'KM per jaar',
                    options: [
                        {
                            label: '10.000',
                            value: '10000',
                        },
                        {
                            label: '15.000',
                            value: '15000',
                        },
                        {
                            label: '20.000',
                            value: '20000',
                        },
                        {
                            label: '25.000',
                            value: '25000',
                        },
                        {
                            label: '30.000',
                            value: '30000',
                        },
                    ],
                },
                {
                    slug: 'contract_duration',
                    title: 'Contractduur in maanden',
                    type: 'InlineRadio',
                    options: [
                        {
                            label: '12 mnd',
                            value: '12',
                        },
                        {
                            label: '24 mnd',
                            value: '24',
                        },
                        {
                            label: '36 mnd',
                            value: '36',
                        },
                        {
                            label: '48 mnd',
                            value: '48',
                        },
                        {
                            label: '60 mnd',
                            value: '60',
                        },
                    ],
                },
            ],
            mobilePanels: [
                {
                    slug: 'km',
                    type: 'Dropdown',
                    title: 'KM per jaar',
                    options: [
                        {
                            label: '10.000',
                            value: '10000',
                        },
                        {
                            label: '15.000',
                            value: '15000',
                        },
                        {
                            label: '20.000',
                            value: '20000',
                        },
                        {
                            label: '25.000',
                            value: '25000',
                        },
                        {
                            label: '30.000',
                            value: '30000',
                        },
                    ],
                },
                {
                    slug: 'contract_duration',
                    title: 'Contractduur',
                    type: 'Dropdown',
                    options: [
                        {
                            label: '12 maanden',
                            value: '12',
                        },
                        {
                            label: '24 maanden',
                            value: '24',
                        },
                        {
                            label: '36 maanden',
                            value: '36',
                        },
                        {
                            label: '48 maanden',
                            value: '48',
                        },
                        {
                            label: '60 maanden',
                            value: '60',
                        },
                    ],
                },
            ],
            showEmptyView: false,
        };
    },
    computed: {
        formattedProductOffers() {
            return this.productOffers.map((productOffer) => Object.assign(productOffer, {
                provider_logo: productOffer.provider_logo,
                total_price: productOffer.monthly_price,
                provider_name: productOffer.provider_name,
            }));
        },
        bestOffer() {
            return this.productOffers[0];
        },
    },
    watch: {
        filters: {
            handler(newValue, oldValue) {
                this.getProductOffers();

                this.logAnalyticsEvent('compare-privatelease.filter-offers', {
                    km: this.filters.km,
                    contract_duration: this.filters.contract_duration,
                });
            },
            deep: true,
        },
    },
    created() {
        this.filters.model = this.productId;
        this.initialFilters = JSON.parse(JSON.stringify(this.filters));
    },

    mounted() {
        if (typeof localStorage !== 'undefined') {
            // Read data from localStorage and use it as filter options
            const savedFilters = JSON.parse(
                localStorage.getItem('privateLeaseFilters'),
            );
            if (savedFilters) {
                this.filters.km = savedFilters.km;
                this.filters.contract_duration = savedFilters.contract_duration;
            }
        }
    },
    methods: {
        getOffers(filter) {
            return fetch(`/flamingo/private-lease/offers?${new URLSearchParams(filter).toString()}`)
                .then((response) => response.json());
        },
        postQuote(offerId, data) {
            return fetch(`/flamingo/private-lease/quote/${offerId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            })
                .then((response) => response.json());
        },
        getProductOffers() {
            this.loading = true;

            this.getOffers(this.filters)
                .then((data) => {
                    const offers = data.offers.filter((offer) => offer.has_affiliate === true);
                    this.showEmptyView = offers.length === 0;
                    this.productOffers = offers;
                    this.updateProductCard();
                })
                .then(
                    setTimeout(() => {
                        this.loading = false;
                    }, 840),
                );
        },
        updateFilter(event) {
            this.filters[event.slug] = event.value;

            if (typeof localStorage === 'undefined') {
                return;
            }
            // Update localStorage filter
            const savedFilters = JSON.parse(
                localStorage.getItem('privateLeaseFilters'),
            );
            if (this.productOffers.length > 0 && savedFilters) {
                savedFilters.km = this.filters.km;
                savedFilters.contract_duration = this.filters.contract_duration;
                localStorage.setItem(
                    'privateLeaseFilters',
                    JSON.stringify(savedFilters),
                );
            } else if (this.productOffers.length > 0) {
                localStorage.setItem(
                    'privateLeaseFilters',
                    JSON.stringify(this.filters),
                );
            }
        },
        handleProductClick(product) {
            if (product.has_affiliate) {
                if (typeof localStorage !== 'undefined') {
                    // Save some product data to the localStorage for sharing with the clickOut page
                    localStorage.setItem(
                        'privateLeaseProductData',
                        JSON.stringify(product),
                    );
                }

                window
                    .open(
                        `/private-lease/meer-informatie?prodid=${this.cleanProductId(product.id)}`,
                        '_blank',
                    )
                    .focus();

                this.logAnalyticsEvent('compare-privatelease.outclick', {
                    car_model: product.full_name,
                    contract_duration: product.contract_duration,
                    km: product.km,
                    provider: product.provider_slug,
                });
            }
        },
        cleanProductId(productId) {
            return productId.split('-')[1];
        },
        resetFilters() {
            this.filters = JSON.parse(JSON.stringify(this.initialFilters));
        },
        updateProductCard() {
            const productCard = document.querySelector('.replace-private-lease-content');
            if (productCard === null) {
                return;
            }

            if (typeof localStorage !== 'undefined') {
                // Save some product data to the localStorage for sharing with the clickOut page
                localStorage.setItem(
                    'privateLeaseProductData',
                    JSON.stringify(this.bestOffer),
                );
            }

            if (this.productOffers.length === 0) {
                productCard.classList.add('loaded');
                return;
            }

            const id = productCard?.dataset.offerId;
            if (id !== this.bestOffer.id) {
                const replaceableContent = ['contract_duration', 'km', 'monthly_price', 'provider_name'];
                const formattedPrice = this.toCurrencyShortEur(this.bestOffer.monthly_price);
                // Replace the innerHTML of the span elements within the productCard that have a data-replace="key" selector matching the replacableContent key with data from the cheapest offer
                replaceableContent.forEach((key) => {
                    productCard.querySelectorAll(`span[data-replace="${key}"]`)
                        .forEach((element) => {
                            if (key === 'monthly_price') {
                                if (element.innerHTML !== formattedPrice) {
                                    element.innerHTML = this.toCurrencyShortEur(this.bestOffer[key]);
                                }
                            } else {
                                element.innerHTML = this.bestOffer[key];
                            }
                        });
                });

                productCard.querySelector('.signup-link').href = `/private-lease/meer-informatie?prodid=${this.cleanProductId(this.bestOffer.id)}`;
                productCard.classList.add('loaded');
            } else {
                productCard.classList.add('loaded');
            }
        },

        deviatesFromFilterKm(productOffer) {
            return (
                parseInt(productOffer.km) !== parseInt(this.filters.km)
            );
        },
        deviatesFromFilterContractDuration(productOffer) {
            return (
                parseInt(productOffer.contract_duration) !== parseInt(this.filters.contract_duration)
            );
        },
    },
};
</script>
