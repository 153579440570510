<template>
  <div class="mobile-filter-bar">
    <component
      v-for="panel in panels"
      :key="panel.slug"
      v-bind:is="panel.type"
      :panel="panel"
      :value="getPanelValue(panel.slug)"
      comparer-name="private-lease-vergelijker"
      @value="updateFilter"
    ></component>
    <button-icon-right
      @click.native="showMobileFilters"
      icon="x-fa-filter"
      label="Filters aanpassen"
      type="ghost"></button-icon-right>
    <active-filters
      v-bind:customSelection="customSelection"
      @removeSelectedFilter="removeSelectedFilter($event)"
      @clearSelectedFilters="$emit('clearSelectedFilters', $event)"
    >
    </active-filters>
  </div>
</template>

<script>
import Dropdown from "vue-elements/sidebar/panels/Dropdown.vue";
import Icon from "vue-elements/icon/Icon.vue";
import ActiveFilters from "./ActiveFilters.vue";
import ButtonIconRight from '../../../../abstracts/elements/button/icon-right/button-icon-right.vue';

export default {
  props: ["selection", "customSelection"],

  data: function () {
    return {
      panels: {
        km: {
          slug: "km",
          type: "Dropdown",
          title: "",
          options: [
            {
              label: "10.000 km/j",
              value: "10000",
            },
            {
              label: "15.000 km/j",
              value: "15000",
            },
            {
              label: "20.000 km/j",
              value: "20000",
            },
            {
              label: "25.000 km/j",
              value: "25000",
            },
            {
              label: "30.000 km/j",
              value: "30000",
            },
          ],
        },
        contract_duration: {
          slug: "contract_duration",
          type: "Dropdown",
          title: "",
          options: [
            {
              label: "12 maanden",
              value: "12",
            },
            {
              label: "24 maanden",
              value: "24",
            },
            {
              label: "36 maanden",
              value: "36",
            },
            {
              label: "48 maanden",
              value: "48",
            },
            {
              label: "60 maanden",
              value: "60",
            },
          ],
        },
      },
    };
  },
  methods: {
    updateFilter(event) {
      this.$emit("filter", event);
    },

    showMobileFilters() {
      this.$emit("openSidebar");
    },
    getPanelValue(panelSlug) {
      return this.selection[panelSlug];
    },

    removeSelectedFilter($event) {
      this.$emit("removeSelectedFilter", $event);
    },
  },

  components: {
    ButtonIconRight,
    Dropdown,
    Icon,
    ActiveFilters,
  },
};
</script>

<style lang="scss" >
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/colors' as colors;
@use 'abstracts/elements/button/icon-right/button-icon-right';


.mobile-filter-bar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 16px;
  margin-bottom: 16px;
  @include breakpoint.up(md) {
    grid-template-columns: 1fr 1fr auto;
  }

  @include breakpoint.up(xl) {
    display: none;
  }
  .button-element.ghost.icon-right {
    grid-column: 1 / span 2;
    @include button-icon-right.ghost
  }

  .active-filters {
    grid-column: 1 / span 2;

    @include breakpoint.up(md) {
      grid-column: 1 / span 3;
    }
    .divider {
      display: none;
    }
  }
}
</style>
