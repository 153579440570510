<template>
  <div class="base" :class="display">
    <span class="text based-on" v-if="base.labels && base.labels.length > 0">
      <span class="label">
        Dit overzicht is gebaseerd op de volgende gegevens:
      </span>
      <span class="base-item" v-for="baseLabel in base.labels" :key="baseLabel">
        <span class="bullet"></span>
        {{ baseLabel }}
      </span>
    </span>
    <span class="text date" v-if="base.check_date">
      Peildatum: {{ toDateString(base.check_date) }}
    </span>
    <span class="text price-estimated" v-if="show_price_estimated">
      *De prijzen zijn een accurate inschatting van het te verwachten bedrag.
    </span>
  </div>
</template>

<style lang="scss">
@use 'assets/scss/_variables/colors' as colors;
@use 'assets/scss/_helpers/typography'as typography;
@use 'assets/scss/_variables/typographies'as typographies;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;

.base {
  @include typography.font(typographies.$fonts, body-alt);

  color: colors.$text-base;

  &.mobile {
    @include breakpoint.up(xl) {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @include breakpoint.up(xl) {
      display: initial;
    }
  }

  .text {
    display: block;
    margin-top: 12px;
  }

  .based-on {
    display: flex;
    flex-flow: column;
  }

  .bullet {
    display: flex;
    min-width: 6px;
    height: 6px;
    border-radius: 8px;
    margin-right: 6px;
    background-color: colors.$text-base;
  }

  .base-item {
    @include typography.font(typographies.$fonts, body-alt );

    margin-top: 4px;
    margin-left: 4px;
    line-height: 28px;
    display: flex;
    align-items: center;
  }

  .price-estimated {
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}
</style>

<script>
import Icon from "../../../vue-elements/icon/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: ["show_price_estimated", "base", "display"],
};
</script>
