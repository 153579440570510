<template>
  <div class="seo-table">

    <h3 v-if="customSelection['car_maker'] && filters.car_maker !== ''">{{ customSelection['car_maker'] }} private
      lease</h3>
    <h3 v-else-if="filters.fuel_type === 'electric'">Elektrisch private lease overzicht</h3>
    <h3 v-else-if="filters.used_cars === 'only'">Occasion private lease overzicht</h3>
    <h3 v-else>Private lease overzicht</h3>

    <div class="seo-table-content">
      <template v-for="(products, brand) in productByBrand">
        <div class="brand-container">
          <h3 class="h6">{{ brand }} private lease</h3>
          <div class="brand-grid">
            <div class="link-item" v-for="product in sortedProducts(products)">
              <span class="car-title"><a :href="product._url" :title="hrefTitle(product)">{{ product.car_maker_name }} {{
                  product.name
                }}</a></span>
              <span class="car-price">vanaf &euro; {{ product.cheapest_offer_price }}</span>
              <span class="line"></span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['products', 'filters', 'customSelection'],
  data: function () {
    return {
      lastBrand: null,
    };
  },
  methods: {
    hrefTitle(product) {
      return product.car_maker_name + ' ' + product.name + ' private lease';
    },
    showBrandTitle(brand) {
      let isSameBrand = this.lastBrand !== brand;
      this.lastBrand = brand;
      return isSameBrand;
    },
    sortedProducts: function (products) {
      return products.sort((a, b) => a.cheapest_offer_price - b.cheapest_offer_price);
    },
  },
  computed: {
    productByBrand: function () {
      let productArray = Object.keys(this.products)
          .map((k) => this.products[k]);
      let productByBrand = {};

      productArray.forEach((product) => {
        if (!productByBrand[product.car_maker_name]) {
          productByBrand[product.car_maker_name] = [];
        }
        productByBrand[product.car_maker_name].push(product);
      });

      productByBrand = Object.keys(productByBrand)
          .sort()
          .reduce(
              (obj, key) => {
                obj[key] = productByBrand[key];
                return obj;
              },
              {}
          );

      return productByBrand;
    }
  }
};
</script>

<style lang="scss">
.private-lease-comparison-module .seo-table {
  width: 100%;
  margin-top: 20px;
  background: #F2FDFF;
  border: 1px solid #C4E3ED;
  border-radius: 20px;
  padding: 40px;

  .seo-table-content {
    columns: 3 220px;
    column-gap: 40px;
  }

  .brand-container {
    display: flex;
    flex-direction: column;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .brand-container h3.h6 {
    margin-bottom: 0px;
    margin-top: 12px;
  }

  .brand-grid {
    display: flex;
    flex-direction: column;
  }

  .link-item {
    display: inline-flex;
    justify-content: space-between;
    position: relative;
    line-height: 1.7em;

    a {
      color: #005e7b;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    & > span {
      background-color: #f2fdff;
      z-index: 2;
      padding: 0 5px;
      white-space: nowrap;
    }
  }

  .car-title {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0 !important;
    max-width: 200px;
  }

  .line {
    position: absolute;
    bottom: 7px;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, transparent 50%, #C4E3ED 50%);
    background-size: 1px 2px, 100% 2px;
    z-index: 1 !important;
  }
}

</style>
