<template>
  <div
    class="private-lease-comparison-module"
    :class="{ 'is-loading': loading }"
  >
    <sort-bar
      :options="sortOptions"
      :current="filters.sort"
      @sort="sortProducts"
    />
    <div
      class="comparer-sidebar"
      :class="sidebarClass"
      @click.self="closeSidebar()"
    >
      <sidebar
        :panels="panels"
        v-bind="panels"
        :selection="filters"
        :custom-selection="customFilters"
        @filter="updateFilter"
        @close="closeSidebar()"
        @removeSelectedFilter="removeSelectedFilter($event)"
        @clearSelectedFilters="clearSelectedFilters()"
      />
    </div>
    <mobile-filter-bar
      :selection="filters"
      :custom-selection="customFilters"
      @filter="updateFilter"
      @openSidebar="openSidebar"
      @removeSelectedFilter="removeSelectedFilter($event)"
      @clearSelectedFilters="clearSelectedFilters()"
    />
    <div class="comparer-body">
      <div
        v-if="showLocalLoader"
        :key="-3"
        class="local-loader"
      >
        <span class="loader">
          <icon
            icon="x-fa-spinner-third"
            size="xl"
          />
        </span>
      </div>

      <empty-view
        v-if="showEmptyView"
        :key="-2"
        icon="x-fa-car-crash"
        @reset="resetFilters()"
      >
        <template #heading>
          Geen private lease auto's gevonden
        </template>
        <template #message>
          Momenteel hebben we helaas nog geen auto’s bij de door jou aangegeven
          voorkeuren. We zijn hard bezig om het private lease aanbod uit te
          breiden. Een collega op de chat helpt je graag verder!
        </template>
      </empty-view>
      <TransitionProductCards>
        <product-card
          v-for="(product, index) in paginatedProducts"
          v-show="!loading && !showEmptyView"
          :key="product.id"
          :product="product"
          :filters="filters"
          :index="index"
          @offerClick="handleOfferClick($event)"
        />
      </TransitionProductCards>
      <pagination-bar
        v-if="showPaginationBar"
        :key="-1"
        :cars-per-page="nextPaginationCount"
        :loading="paginationLoading"
        :total-cars="totalProducts"
        @click="loadMoreCars()"
      />
    </div>
    <seo-table
      v-if="seoLinkTable"
      :custom-selection="customFilters"
      :filters="filters"
      :products="products"
    />
  </div>
</template>

<script>
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { nextTick } from 'vue';
import TransitionProductCards from 'vue-elements/transitions/TransitionProductCards.vue';
import SeoTable from './components/SeoTable.vue';
import Sidebar from './components/Sidebar.vue';
import ProductCard from './components/ProductCard.vue';
import MobileFilterBar from './components/MobileFilterBar.vue';

import PaginationBar from './components/PaginationBar.vue';
import SortBar from './components/SortBar.vue';
import EmptyView from '../../vue-elements/product-card/EmptyView.vue';
import Icon from '../../vue-elements/icon/Icon.vue';

export default {
    components: {
        TransitionProductCards,
        SeoTable,
        Sidebar,
        ProductCard,
        MobileFilterBar,
        PaginationBar,
        SortBar,
        EmptyView,
        Icon,
    },
    props: {
        config: {
            type: Object,
            default: null,
        },
        carmaker: {
            type: String,
            default: '',
        },
        fueltype: {
            type: String,
            default: '',
        },
        usedcars: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            initializing: false,
            xhrCancelToken: null,
            isOfferModalActive: Boolean,
            offerModalProduct: Object,
            loading: false,
            paginationLoading: false,
            showEmptyView: false,
            currentPage: 0,
            sidebarClass: 'default',
            totalProducts: 0,
            seoLinkTable: true,
            products: this.config ? this.config.cars : [],
            panels: {
                km: {
                    slug: 'km',
                    type: 'InlineRadio',
                    title: 'Kilometer per jaar',
                    default: 10000,
                    options: [
                        {
                            label: '10.000',
                            value: '10000',
                        },
                        {
                            label: '15.000',
                            value: '15000',
                        },
                        {
                            label: '20.000',
                            value: '20000',
                        },
                        {
                            label: '25.000',
                            value: '25000',
                        },
                        {
                            label: '30.000',
                            value: '30000',
                        },
                    ],
                },
                contract_duration: {
                    slug: 'contract_duration',
                    type: 'InlineRadio',
                    title: 'Contractduur',
                    default: 60,
                    options: [
                        {
                            label: '12 mnd',
                            value: '12',
                        },
                        {
                            label: '24 mnd',
                            value: '24',
                        },
                        {
                            label: '36 mnd',
                            value: '36',
                        },
                        {
                            label: '48 mnd',
                            value: '48',
                        },
                        {
                            label: '60 mnd',
                            value: '60',
                        },
                    ],
                },
                fuel_type: {
                    slug: 'fuel_type',
                    title: 'Brandstof',
                    tooltip: null,
                    type: 'LargeRadio',
                    default: '',
                    options: [
                        {
                            label: 'Toon alles',
                            value: '',
                        },
                        {
                            label: '100% elektrisch',
                            sublabel: null,
                            value: 'electric',
                        },
                        {
                            label: 'Benzine',
                            sublabel: null,
                            value: 'petrol',
                        },
                    ],
                },
                price_range: {
                    slug: 'price_range',
                    title: 'Prijs per maand',
                    tooltip: null,
                    type: 'Range',
                    default: null,
                    options: {
                        min_value: {
                            label: 'min_price',
                            default: null,
                            value: '',
                        },
                        max_value: {
                            label: 'max_price',
                            default: null,
                            value: '',
                        },
                    },
                },
                car_maker: {
                    slug: 'car_maker',
                    title: 'Automerk',
                    tooltip: null,
                    type: 'Dropdown',
                    default: '',
                    options: [
                        {
                            label: 'Alle automerken',
                            value: '',
                        },
                    ],
                },
                used_cars: {
                    slug: 'used_cars',
                    title: 'Occasions',
                    tooltip: null,
                    type: 'Dropdown',
                    default: '',
                    options: [
                        {
                            label: 'Nieuw en gebruikt',
                            value: '',
                        },
                        {
                            label: 'Alleen nieuw',
                            sublabel: null,
                            value: 'exclude',
                        },
                        {
                            label: 'Alleen gebruikt',
                            sublabel: null,
                            value: 'only',
                        },
                    ],
                },
            },
            sortOptions: [
                {
                    label: 'Populairste eerst',
                    icon: 'x-fa-sort-amount-down',
                    value: 'popular',
                },
                {
                    label: 'Goedkoopste eerst',
                    icon: 'x-fa-sort-amount-down-alt',
                    value: 'price',
                },
                {
                    label: 'Duurste eerst',
                    icon: 'x-fa-sort-amount-down',
                    value: 'price_reverse',
                },
            ],
            defaultFilters: {
                page: 0,
                items_per_page: 10,
                km: 10000,
                fuel_type: '',
                contract_duration: '60',
                car_maker: '',
                min_price: '',
                max_price: '',
                sort: 'popular',
                used_cars: '',
            },
            filters: {
                page: 0,
                items_per_page: 10,
                km: '10000',
                fuel_type: '',
                contract_duration: '60',
                car_maker: '',
                min_price: '',
                max_price: '',
                sort: 'popular',
                used_cars: '',
            },
            customFilters: {
                km: null,
                fuel_type: null,
                contract_duration: null,
                car_maker: null,
                min_price: null,
                max_price: null,
                used_cars: null,
            },
            previousFilters: Object,
        };
    },
    computed: {
        showPaginationBar() {
            if (this.loading) {
                return false;
            }
            return (
                ((this.currentPage + 1) * this.filters.items_per_page) < this.totalProducts
            );
        },
        nextPaginationCount() {
            return this.totalProducts - this.filters.items_per_page
            >= this.filters.items_per_page
                ? this.filters.items_per_page
                : this.totalProducts - this.filters.items_per_page;
        },
        showLocalLoader() {
            return this.products.length === 0 && this.loading;
        },
        paginatedProducts() {
            if (this.products == null) {
                return [];
            }

            let itemsPerPage = this.filters.items_per_page;
            if (itemsPerPage < 1) {
                itemsPerPage = this.defaultFilters.items_per_page;
            }

            return Object.values(this.products)
                .slice(0, (this.currentPage + 1) * itemsPerPage);
        },
    },
    watch: {
        filters: {
            handler() {
                if (!this.initializing) {
                    this.getProducts();
                    this.logAnalyticsEvent('compare-privatelease.catalog-filter');
                }
            },
            deep: true,
        },
        customFilters: {
            handler() {
                if (
                    this.customFilters.km === null
                    || this.customFilters.contract_duration === null
                ) {
                    this.customFilters.km = this.panels.km.default;
                    this.customFilters.contract_duration = this.panels.contract_duration.default;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        if (this.config) {
            this.setCarMakerDropdown(this.config.car_makers);
            this.setMetaValues(this.config);
        }
        this.offerModalProduct = null;
        this.isOfferModalActive = false;
    },
    mounted() {
        let pageFilters = JSON.parse(localStorage.getItem(`privateLeaseFilters_${window.location.href}`));
        let globalFilters = JSON.parse(localStorage.getItem('privateLeaseFilters'));

        if (pageFilters == null) {
            pageFilters = {};
        }
        if (globalFilters == null) {
            globalFilters = {};
        }

        let savedFilters = {
            ...pageFilters,
            ...globalFilters,
        };

        if (savedFilters) {
            this.fromLocalFilters = true;
            savedFilters = {
                page: 0,
                items_per_page: 10,
                km: savedFilters.km ? savedFilters.km : 10000,
                fuel_type: savedFilters.fuel_type ? savedFilters.fuel_type : '',
                contract_duration: savedFilters.contract_duration
                    ? savedFilters.contract_duration
                    : 60,
                used_cars: savedFilters.used_cars ? savedFilters.used_cars : '',
                car_maker: savedFilters.car_maker ? savedFilters.car_maker : '',
                min_price: savedFilters.min_price ? savedFilters.min_price : '',
                max_price: savedFilters.max_price ? savedFilters.max_price : '',
                sort: savedFilters.sort ? savedFilters.sort : 'popular',
            };

            this.initializing = true;
            this.filters = { ...savedFilters };
            this.customFilters = { ...savedFilters };

            nextTick(() => {
                this.initializing = false;
            });

            // this.getProducts();
        }

        if (this.products.length === 0) {
            this.getProducts();
        }

        this.initializing = true;
        if (this.carmaker) {
            this.panels.car_maker = { default: this.carmaker };
            this.customFilters.car_maker = null;
            this.filters.car_maker = this.carmaker;
            this.defaultFilters.car_maker = this.carmaker;
        }

        if (this.usedcars) {
            this.panels.used_cars = { default: this.usedcars };
            this.customFilters.used_cars = null;
            this.filters.used_cars = this.usedcars;
            this.defaultFilters.used_cars = this.usedcars;
        }

        if (this.fueltype) {
            this.panels.fuel_type = { default: this.fueltype };
            this.customFilters.fuel_type = null;
            this.filters.fuel_type = this.fueltype;
            this.defaultFilters.fuel_type = this.fueltype;
        }

        if (this.$el.dataset.carmaker && !!this.$el.dataset.carmaker.length) {
            this.panels.car_maker = { default: this.$el.dataset.carmaker };
            this.customFilters.car_maker = null;
            this.filters.car_maker = this.$el.dataset.carmaker;
            this.defaultFilters.car_maker = this.$el.dataset.carmaker;
        }

        if (this.config && !!this.config.filters.car_maker.length) {
            this.panels.car_maker = { default: this.config.filters.car_maker };
            this.customFilters.car_maker = null;
            this.filters.car_maker = this.config.filters.car_maker;
            this.defaultFilters.car_maker = this.config.filters.car_maker;
        }

        if (this.config && !!this.config.filters.used_cars.length) {
            this.panels.used_cars = { default: this.config.filters.used_cars };
            this.customFilters.used_cars = null;
            this.filters.used_cars = this.config.filters.used_cars;
            this.defaultFilters.used_cars = this.config.filters.used_cars;
        }

        if (this.config && !!this.config.filters.fuel_type.length) {
            this.panels.fuel_type = { default: this.config.filters.fuel_type };
            this.customFilters.fuel_type = null;
            this.filters.fuel_type = this.config.filters.fuel_type;
            this.defaultFilters.fuel_type = this.config.filters.fuel_type;
        }

        let savedAndConfigFilters = savedFilters;
        if (this.config && this.config.filters.length > 0) {
            savedAndConfigFilters = {
                ...savedAndConfigFilters,
                ...this.config.filters,
            };
        }

        if (!isEqual(savedAndConfigFilters, this.filters)) {
            this.getProducts();
        }

        nextTick(() => {
            this.initializing = false;
        });

        // this.initializing = false;
    },
    methods: {
        openSidebar() {
            document.querySelector('body')
                .classList
                .add('filterbar-active');
            this.sidebarClass = 'expanded';
        },
        closeSidebar() {
            document.querySelector('body')
                .classList
                .remove('filterbar-active');
            this.sidebarClass = 'closed';
        },
        setCarMakerDropdown(makersJson) {
            const makerOptions = [{
                label: 'Alle automerken',
                value: '',
            }];
            for (const slug in makersJson) {
                makerOptions.push({
                    label: makersJson[slug],
                    value: slug,
                });
            }
            this.panels.car_maker.options = makerOptions;
            this.setCustomFilterLabel('car_maker', this.customFilters.car_maker);
        },
        setMetaValues(data) {
            this.panels.price_range.options.min_value = data.cheapest_offer_price_min;
            this.panels.price_range.options.max_value = data.cheapest_offer_price_max;
            this.totalProducts = data.total_items;
        },
        loadMoreCars() {
            this.currentPage += 1;
            this.logAnalyticsEvent('compare-privatelease.catalog-moreproducts');
        },
        getProducts() {
            this.loading = true;

            if (this.xhrCancelToken != null) {
                this.xhrCancelToken.cancel();
            }

            this.xhrCancelToken = axios.CancelToken.source();
            axios
                .get('/flamingo/private-lease/models', {
                    params: {
                        ...this.filters,
                        items_per_page: 999,
                    },
                    cancelToken: this.xhrCancelToken.token,
                })
                .then(
                    (response) => (
                        (this.products = response.data.cars),
                        this.setCarMakerDropdown(response.data.car_makers),
                        this.setMetaValues(response.data)
                    ),
                )
                .then(() => {
                    this.showEmptyView = this.products.length === 0;
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        updateFilter(event) {
            this.previousFilters = { ...this.filters };

            this.filters[event.slug] = event.value;
            this.setCustomFilterLabel(event.slug, event.value);

            const globalFilters = {
                km: this.filters.km,
                contract_duration: this.filters.contract_duration,
            };

            const pageFilters = { ...this.filters };
            delete pageFilters.km;
            delete pageFilters.contract_duration;

            localStorage.setItem(`privateLeaseFilters_${window.location.href}`, JSON.stringify(pageFilters));
            localStorage.setItem('privateLeaseFilters', JSON.stringify(globalFilters));
            this.currentPage = 0;
        },
        removeSelectedFilter($event) {
            if ($event === 'sort' || $event === 'items_per_page') {
                return;
            }

            this.customFilters[$event] = null;
            if (this.panels[$event]) {
                this.filters[$event] = this.panels[$event].default
                    ? this.panels[$event].default
                    : '';
            } else {
                this.filters[$event] = '';
            }
        },
        setCustomFilterLabel(filterSlug, filterValue) {
            switch (filterSlug) {
            case 'km':
                this.customFilters[filterSlug] = this.toLocaleNumberFormat(filterValue);
                break;
            case 'car_maker':
                this.panels[filterSlug].options.forEach((option) => {
                    if (option.value === filterValue) {
                        this.customFilters[filterSlug] = option.label;
                    }
                });
                break;
            case 'used_cars':
                this.panels[filterSlug].options.forEach((option) => {
                    if (option.value === filterValue) {
                        this.customFilters[filterSlug] = option.label;
                    }
                });
                break;
            case 'fuel_type':
                if (filterValue === '') {
                    return;
                }
                this.panels[filterSlug].options.forEach((option) => {
                    if (option.value === filterValue) {
                        this.customFilters[filterSlug] = option.label;
                    }
                });
                break;
            default:
                this.customFilters[filterSlug] = filterValue;
                break;
            }
        },
        clearSelectedFilters() {
            for (const filter in this.customFilters) {
                this.removeSelectedFilter(filter);
            }
        },
        sortProducts(sortValue) {
            this.updateFilter({
                slug: 'sort',
                value: sortValue,
            });
        },
        resetFilters() {
            if (
                Object.keys(this.previousFilters).length === 0
                || JSON.stringify(this.filters) === JSON.stringify(this.previousFilters)
            ) {
                this.setFilters(this.defaultFilters);
            } else {
                this.setFilters(this.previousFilters);
            }
        },
        setFilters(newFilters) {
            for (const filter in newFilters) {
                this.updateFilter({
                    slug: filter,
                    value: newFilters[filter],
                });
            }
        },
        handleOfferClick(offer) {
            if (offer.has_affiliate) {
                // Save some product data to the localStorage for sharing with the clickOut page
                localStorage.setItem('privateLeaseProductData', JSON.stringify(offer));

                window
                    .open(
                        `/private-lease/meer-informatie?prodid=${this.cleanProductId(
                            offer.id,
                        )}`,
                        '_blank',
                    )
                    .focus();

                this.logAnalyticsEvent('compare-privatelease.outclick', {
                    car_model: offer.full_name,
                    contract_duration: offer.contract_duration,
                    km: offer.km,
                    provider: offer.provider_slug,
                });
            } else {

            }
        },

        cleanProductId(productId) {
            return productId.split('-')[1];
        },
        formattedProductOffer(product) {
            return Object.assign(product, {
                provider_logo: product.provider_logo,
                total_price: product.monthly_price,
                provider_name: product.provider_name,
            });
        },
    },
};
</script>
