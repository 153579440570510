<template>
  <div class="sort-bar">
    <SelectInput
        v-model="selected"
        :options="options"
        :icon="sortIcon"
    ></SelectInput>
  </div>
</template>

<script>
import Panel from 'vue-elements/sidebar/panels/Panel.vue';
import Icon from 'vue-elements/icon/Icon.vue';
import SelectInput from 'vue-elements/input/SelectInput.vue';

export default {
  components: {
    SelectInput,
    Icon,
    Panel
  },
  props: ['options', 'current'],
  data: function () {
    return {
      selected: this.current,
    };
  },

  computed: {
    sortIcon() {
      if (this.selected === 'price') {
        return 'x-fa-sort-amount-down-alt';
      } else if (this.selected === 'price-reverse') {
        return 'fa-sort-amount-down';
      }
      return 'x-fa-sort-amount-down';
    },
  },
  watch: {
    selected: function () {
      this.$emit('sort', this.selected);
    },
    current: function () {
      this.selected = this.current;
    }
  },
};
</script>

<style lang="scss">

.private-lease-comparison-module .sort-bar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

</style>
