<template>
  <div class="product-card" :class="'index-' + index">
    <div class="product-info">
      <div class="product-title">
        <a :href="product._url" :title="`${product.car_maker_name} ${product.name} private lease`">
          <h3 class="h4 skeleton">
            {{ product.car_maker_name }} {{ product.name }}
          </h3>
        </a>
      </div>
      <div class="product-icons">
        <simple-icon-element icon="car">
          <template #label>
            <div>
              <transition name="value-update">
              <span class="value" :key="filters.km">
                {{ toLocaleNumberFormat(parseInt(filters.km, 10)) }}
              </span>
              </transition>
            </div>
            &nbsp;km/jr&nbsp;
          </template>
        </simple-icon-element>
        <simple-icon-element icon="calendar">
          <template #label>
            <div>
              <transition name="value-update">
              <span class="value" :key="filters.contract_duration">
                {{ filters.contract_duration }}
              </span>
              </transition>
            </div>
            &nbsp;maanden
          </template>
        </simple-icon-element>
      </div>
    </div>
    <a :href="product._url" class="product-image skeleton"
       :title="`${product.car_maker_name} ${product.name} private lease`"
    >
      <img
          :src="product.car_image"
          loading="lazy"
          :alt="product.car_maker_name + ' ' + product.name"
          height="88"
          width="142"
      />
    </a>
    <div class="product-offers">

      <transition-group name="value-update">
        <div
            class="offer skeleton"
            v-for="offer in product.offers.slice(0, 3)"
            :key="offer.id"
            @click="$emit('offerClick', offer)"
        >
          <div class="provider-name">{{ offer.provider_name }}</div>
          <div class="offer-price">
          <span class="price"
          >{{ toCurrencyShortEur(offer.monthly_price) }}
          </span>
            <span class="label">&nbsp;p/m</span>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="skeleton">

      <a :href="product._url" :title="`${product.car_maker_name} ${product.name} private lease`"
         class="link-element button primary " role="button"
      >
        <span class="label mobile">Vergelijken</span>
        <span class="label desktop" v-if="product.offer_count > 1">Vergelijk {{ product.offer_count }} aanbieders</span>
        <span class="label desktop" v-else>Bekijk {{ product.offer_count }} aanbieder</span>
        <icon icon="arrow-right-long-solid"></icon>
      </a>
    </div>
  </div>
</template>

<script>
import SimpleIconElement from 'vue-elements/product-card/SimpleIconElement.vue';
import Icon from 'vue-elements/icon/Icon.vue';

export default {
  props: ['product', 'index', 'comparerName', 'filters'],
  components: {
    SimpleIconElement,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@use 'assets/scss/_helpers/box' as box;
@use 'assets/scss/_helpers/border' as border;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_helpers/typography' as typography;
@use 'assets/scss/_helpers/display' as display;
@use 'assets/scss/_variables/colors';
@use "../../../elements/link/button/_shared/link";
//
.private-lease-comparison-module {
  $offer-font: (14px, 14px, 500);

  .product-card {
    @include border.radius(xl);

    position: relative;
    background-color: white;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include breakpoint.up(lg) {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 1fr;
      justify-content: center;
      padding: 32px;
    }

    .product-image {
      @include display.flex-row-center;

      align-items: center;

      @include breakpoint.up(lg) {
        grid-row: 1;
      }

      img {
        height: 88px;
        width: 100%;
        object-fit: contain;
        @include breakpoint.up(lg) {
          height: 146px;
        }
      }
    }

    .product-info {
      @include breakpoint.up(lg) {
        @include display.flex-col-center;

        grid-column: 1;
        grid-row: 2;
      }
    }

    .product-title h3 {
      margin-top: 8px;
      color: #000;
    }

    .product-icons {
      @include breakpoint.up(lg) {
        @include display.flex-row-between;

        width: 100%;
      }
    }

    .elementgroup {
      display: flex;
    }

    .product-offers {
      display: flex;
      flex-flow: column;
      grid-column: 1 / span 2;
      margin: 16px 0;

      @include breakpoint.up(lg) {
        grid-column: 1;
      }
    }

    .offer {
      @include typography.font($offer-font);

      display: flex;
      padding: 16px 12px;
      justify-content: space-between;
      margin-bottom: 4px;
      background-color: colors.$element-grey-10;
      border: 1px solid colors.$border-base;
      cursor: pointer;

      &:nth-of-type(1) {
        @include border.radius-top(m);
      }

      &:nth-of-type(3) {
        @include border.radius-bottom(m);
      }
    }

    .offer-price {
      display: flex;
      align-items: center;

      .price {
        font-weight: 700;
      }
    }

    .link-element.button.primary {
      @extend %button;
      @extend %button--primary;

      grid-column: 2;
      height: fit-content;

      @include breakpoint.up(md) {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      .label {
        &.mobile {
          @include breakpoint.up(lg) {
            display: none;
          }
        }

        &.desktop {
          display: none;

          @include breakpoint.up(lg) {
            display: inherit;
          }
        }
      }
    }
  }
}
</style>
