<template>
  <div class="sidebar">
    <div class="sidebar-heading">
      <span class="title"> Jouw keuzes </span>
      <span
        class="close-sidebar"
        @click="closeSidebar"
      >
        <icon
          icon="x-fa-times-circle"
          size="m"
        />
      </span>
    </div>
    <active-filters
      :custom-selection="customSelection"
      @removeSelectedFilter="removeSelectedFilter($event)"
      @clearSelectedFilters="clearSelectedFilters()"
    />
    <panel-group
      :panels="panels"
      :selection="selection"
      :analytics-name="analyticsName"
      @filter="updateFilter"
    />
    <compare-base
      v-if="base"
      :show_price_estimated="meta.prices_estimated"
      :base="base"
      display="desktop"
    />
    <sticky-button @close="closeSidebar" />
  </div>
</template>

<script>
import Icon from 'vue-elements/icon/Icon.vue';
import CompareBase from 'vue-elements/sidebar/elements/CompareBase.vue';
import StickyButton from 'vue-elements/sidebar/elements/StickyButton.vue';
import PanelGroup from 'vue-elements/sidebar/PanelGroup.vue';
import ActiveFilters from './ActiveFilters.vue';

export default {
    components: {
        Icon,
        CompareBase,
        StickyButton,
        ActiveFilters,
        PanelGroup,
    },
    props: [
        'panels',
        'base',
        'meta',
        'analyticsName',
        'selection',
        'customSelection',
    ],
    methods: {
        updateFilter(filter) {
            this.$emit('filter', filter);
        },
        closeSidebar() {
            this.$emit('close');
        },
        removeSelectedFilter($event) {
            this.$emit('removeSelectedFilter', $event);
        },
        clearSelectedFilters() {
            this.$emit('clearSelectedFilters');
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'assets/scss/_helpers/background'as background;
@use 'assets/scss/_helpers/breakpoint'as breakpoint;
@use 'assets/scss/_helpers/typography'as typography;
@use 'assets/scss/_helpers/z-index' as z-index;
@use 'assets/scss/_variables/colors';
@use '../styles/animations' as animation;

$heading-font: (
      xxs: (
            24px,
            140%,
            700,
      ),
      md: (
            32px,
            130%,
      ),
);

.comparer-sidebar {
  max-height: 100vh;
  overflow: hidden;

  @include breakpoint.up(xl) {
    max-height: unset;
    height: fit-content;
    padding: 16px 0;
  }

  @include breakpoint.down(xl) {
    @include z-index.overlay;

    position: fixed;
    top: 0;
    bottom: 0;
  }

  .sidebar-heading {
    position: relative;
    display: flex;
    align-items: center;

    @include breakpoint.up(xl) {
      display: none;
    }

    @include breakpoint.down(md) {
      margin-bottom: 16px;
    }

    .title {
      @include typography.font($heading-font);
    }
  }

  .close-sidebar {
    position: absolute;
    right: 0;
  }
}

.sidebar {
  @include background.light;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 32px;
  width: 100%;
  transition-timing-function: cubic-bezier(0.76, -0.08, 0.6, 1.34);
  transition-property: transform;
  max-width: 336px;

  @include breakpoint.down(xl) {
    @include background.light;

    transform: translateX(-100%);
    position: absolute;
    min-height: 100%;
    margin-right: 62px;
    border-radius: 0 15px 15px 0;
    padding-bottom: 0;
  }

  .panel-title {
    padding: 24px 0 8px 0;
  }

  .title {
    display: block;
  }

  .field {
    margin-bottom: 8px;
  }

  .active-filters {
    .divider {
      display: none;
    }
  }
}

.comparer-sidebar.expanded {
  @include breakpoint.down(xl) {
    @include animation.expand-sidebar(0.4s);

    overflow: scroll;
  }
}

.comparer-sidebar.closed {
  @include breakpoint.down(xl) {
    @include animation.collapse-sidebar(0.4s);
  }
}
</style>
