<template>
  <panel
    :panel="panel"
    :slug="panel.slug"
    :value="value"
    :comparer-name="analyticsName"
  >
    <template #panelTitle />
    <template #panelInput>
      <div class="input-container">
        <TextInput
          id="min-price"
          v-model="min_price_value"
          type="number"
          input-mode="numeric"
          name="min-price"
          :min="min_default"
          :max="max_default"
          :placeholder="`${min_default}`"
          @change="updateValues()"
        />

        <span class="divider"> - </span>
        <TextInput
          id="max-price"
          v-model="max_price_value"
          type="number"
          input-mode="numeric"
          name="max-price"
          :min="min_default"
          :max="max_default"
          :placeholder="`${max_default}`"
          @change="updateValues()"
        />

        <!--        <ButtonIconOnly-->
        <!--            @click.native="updateValues()"-->
        <!--            type="cta"-->
        <!--        ></ButtonIconOnly>-->
      </div>
    </template>
  </panel>
</template>

<script>
import Icon from 'vue-elements/icon/Icon.vue';
import TextInput from 'vue-elements/input/TextInput.vue';
import Panel from './Panel.vue';
import ButtonIconOnly from '../../../../abstracts/elements/button/icon-only/button-icon-only.vue';

export default {
    components: {
        ButtonIconOnly,
        TextInput,
        Panel,
        Icon,
    },
    props: ['panel', 'value', 'analyticsName'],
    data() {
        return {
            min_default: null,
            max_default: null,
            min_price_value: null,
            max_price_value: null,
        };
    },
    watch: {
        value: {
            handler() {
                this.min_price_value = this.value.min_price;
                this.max_price_value = this.value.max_price;
            },
        },
        panel: {
            handler() {
                this.updateMeta();
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.updateMeta();
    },
    methods: {
        updateValues() {
            if (this.min_price_value) {
                this.$emit('value', {
                    slug: 'min_price',
                    value: this.min_price_value,
                });
            }
            if (this.max_price_value) {
                this.$emit('value', {
                    slug: 'max_price',
                    value: this.max_price_value,
                });
            }
        },
        updateMeta() {
            this.min_default = this.panel.options.min_value;
            this.max_default = this.panel.options.max_value;
        },
    },
};
</script>
<style lang="scss">
@use 'abstracts/elements/button/icon-only/button-icon-only.scss';

.panel.Range {
  .input-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 12px;
  }

  .button-element.icon-only.cta {
    @include button-icon-only.cta;
  }

}
</style>
