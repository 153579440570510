<template>
  <div class="panel-group">
    <component
      v-for="panel in panels"
      :key="panel.slug"
      v-bind:is="panel.type"
      :panel="panel"
      :analyticsName="analyticsName"
      :value="getPanelValue(panel.slug)"
      @value="updateFilter"
    >
    </component>
  </div>
</template>

<script>
import Icon from "vue-elements/icon/Icon.vue";
import Dropdown from "./panels/Dropdown.vue";
import LargeRadio from "./panels/LargeRadio.vue";
import SmallRadio from "./panels/SmallRadio.vue";
import Range from "./panels/Range.vue";
import InlineRadio from "./panels/InlineRadio.vue";
import Toggle from "./panels/Toggle.vue";

export default {
  props: ["panels", "selection", "analyticsName"],
  components: {
    Icon,
    Dropdown,
    LargeRadio,
    SmallRadio,
    Range,
    InlineRadio,
    Toggle,
  },
  methods: {
    getPanelValue(panelSlug) {
      if (panelSlug === "price_range") {
        return {
          min_price: this.selection.min_price,
          max_price: this.selection.max_price,
        };
      }
      return this.selection[panelSlug];
    },
    updateFilter(filter) {
      this.$emit("filter", filter);
    },
  },
};
</script>

<style>
</style>
