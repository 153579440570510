<template>
  <tr>
    <td class="logo">
      <section class="skeleton">
        <img
          :src="product.provider_logo"
          :alt="product.provider_name"
          :title="product.provider_name"
          @click="$emit('productClick', product)"
        >
      </section>
      <!-- <section v-if="!!index">
        <span class="deal-usp">Goedkoopste deal</span>
      </section> -->
    </td>
    <td class="model">
      <section
        v-if="!index"
        class="skeleton"
      >
        <span class="cheapest">Goedkoopste deal</span>
      </section>
      <section class="skeleton">
        <span><icon
          :size="'sm'"
          icon="x-fa-car"
        />{{ product.full_name }}</span>
        <span v-if="deviatesFromFilterKm"><icon
          :size="'sm'"
          icon="thumbs-up"
        />Meer kilometers:
          {{ toLocaleNumberFormat(parseInt(product.km)) }} in plaats van {{ toLocaleNumberFormat(parseInt(filters.km)) }} </span>
        <span v-if="deviatesFromFilterContractDuration"><icon
          :size="'sm'"
          icon="x-fa-info-square"
        />Contract van
          {{ product.contract_duration }} maanden in plaats van {{ filters.contract_duration }} maanden
        </span>
        <span v-if="product.must_hire"><icon
          :size="'sm'"
          icon="x-fa-handshake"
        />Verplicht auto
          verhuren via Snapcar</span>
        <span v-if="!product.has_damage_free_pricing_influence"><icon
          :size="'sm'"
          icon="x-fa-fragile"
        />Zelfde prijs vanaf 0
          schadevrije jaren</span>
        <span v-if="product.has_full_checkout"><icon
          :size="'sm'"
          icon="x-fa-compass"
        />Direct online aan te
          vragen</span>
      </section>
    </td>
    <td class="base">
      <section>
        <span>Voorwaarden</span>
        <span class="skeleton">{{ toLocaleNumberFormat(product.km) }} km/jaar</span>
        <span class="skeleton">{{ product.contract_duration }} maanden</span>
      </section>
    </td>
    <td class="risk">
      <section class="skeleton">
        <span>Eigen risico</span>
        <span>{{ toCurrencyShortEur(product.own_risk) }}</span>
      </section>
    </td>
    <td class="price">
      <section class="skeleton">
        <span>{{ toCurrencyShortEur(product.monthly_price) }}</span>
        <span>per maand</span>
      </section>
    </td>
    <td class="cta">
      <a
        class="link-element button primary"
        role="button"
        target="_blank"
        rel="nofollow"
        @click="$emit('productClick', product)"
      >
        <slot name="ctaLabel"> Meer info </slot>
        <icon icon="x-fa-arrow-right" />
      </a>
    </td>
  </tr>
</template>

<script>
import Icon from 'vue-elements/icon/Icon.vue';
import ProductCardUsp from 'vue-elements/product-card/ProductCardUsp.vue';

export default {
    components: {
        Icon,
        ProductCardUsp,
    },
    props: [
        'product',
        'location', // Used for URL generation
        'usps',
        'expanded',
        'signupRoute',
        'index',
        'deviatesFromFilterKm',
        'deviatesFromFilterContractDuration',
        'filters',
    ],
};
</script>

<style lang="scss">
@use "../../../../assets/scss/_variables/colors" as colors;
@use "../../../../assets/scss/_helpers/display" as display;
@use "../../../../assets/scss/_helpers/border" as border;
@use "../../../../assets/scss/_helpers/breakpoint" as breakpoint;
@import "../../../elements/link/button/primary/link";

.flamingo-product-display {
  width:100%;
}

.flamingo-product-display {
  table {
    width: 100%;
    display: grid;

    @include breakpoint.up(xl) {
      display: revert;
    }

    thead {
      display: none;
      @include breakpoint.up(xl) {
        display: revert;
      }
    }
    tr {
      @include breakpoint.up(xl) {
        display: revert;
      }

      box-shadow: 0 0 16px rgb(0 0 0 / 20%);
      border-radius: 16px;
      width: 100%;
      margin-bottom: 8px;
      display: grid;
      grid-template-areas:
        "logo price"
        "model model"
        "base risk"
        "cta cta";
      padding: 16px;

      @include breakpoint.up(md) {
        grid-template-areas:
          "logo base base risk price"
          "model model model cta cta";
        margin-bottom: 16px;
      }
      @include breakpoint.up(xl) {
        box-shadow: unset;
        margin-bottom: unset;
        display: revert;
        border: 1px solid #edf5f8;
      }

      th {
        padding: 16px 0;

        &:first-of-type {
          padding-left: 24px;
        }

        &:last-of-type {
          padding-right: 24px;
        }
      }

      td {
        display: flex;

        @include breakpoint.up(xl) {
          display: revert !important;
          padding: 32px 0;

          &:first-of-type {
            padding-left: 24px;
          }

          &:last-of-type {
            padding-right: 24px;
          }
        }
      }
    }

    @include breakpoint.up(xl) {
      box-shadow: 0 0 16px rgb(0 0 0 / 20%);
      border-radius: 16px;
      display: table;
    }

    .logo {
      @include display.flex-col();
      justify-content: center;

      grid-area: logo;

      section {
        &:first-of-type {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      img {
        width: 100%;
        height: auto;
        cursor: pointer;
      }

      .deal-usp {
        color: colors.$link-base-default;
        border-radius: 10px;
        background: colors.$element-grey-20;
        border: 1px solid colors.$border-base;

        padding: 8px;
        font-weight: 500;
        display: none;

        @include breakpoint.up(xl) {
          display: revert;
        }
      }
    }

    .model {
      @include display.flex-col-start();
      grid-area: model;

      @include breakpoint.up(md) {
        font-weight: 700;
      }

      .cheapest {
        color: colors.$text-positive;
        border-radius: 10px;
        padding: 8px;
        font-weight: 500;
        display:inline-block;

        @include breakpoint.up(md) {
          background: revert;
          padding: 0;
        }

        @include breakpoint.up(xl) {
          background-color: colors.$element-positive;
          border: 1px solid colors.$border-positive;
          padding: 8px;
        }
      }

      section:last-of-type {
        display: flex;
        flex-flow: column;

        > span > span:first-of-type {
          margin-right: 8px;

          @include breakpoint.up(xl) {
            // display: none;
          }
        }

        > span {
          line-height: 1.6em;

          @include breakpoint.up(md) {
            line-height: 1.6em;
          }
        }

        > span:not(:first-of-type) {
          // display: none;
          font-weight: 400;

          @include breakpoint.up(md) {
            display: revert;
          }
        }
      }
    }

    .base {
      grid-area: base;

      @include breakpoint.up(md) {
        @include display.flex-row();
        justify-content: flex-end;
      }
      section {
        @include display.flex-col();
        align-items: flex-start;

        line-height: 22px;

        @include breakpoint.up(md) {
          @include display.flex-row();
          justify-content: flex-end;
        }

        @include breakpoint.up(xl) {
          flex-flow: column;
          align-items: flex-start;
        }
      }

      span {
        &:first-of-type {
          font-weight: 700;

          @include breakpoint.up(md) {
            display: none;
          }
        }

        @include breakpoint.up(md) {
          color: colors.$link-base-default;
          background: colors.$element-grey-20;
          border-radius: 10px;
          padding: 8px;
          margin-right: 12px;
        }

        @include breakpoint.up(xl) {
          color: unset;
          border-radius: unset;
          background: unset;
          padding: unset;
          margin-right: unset;
        }
      }
    }

    .risk {
      @include display.flex-col();
      justify-content: flex-start;
      align-items: flex-end;
      grid-area: risk;

      @include breakpoint.up(md) {
        @include display.flex-row();
        justify-content: flex-start;
      }
      section {
        @include display.flex-col();
        align-items: flex-end;

        line-height: 22px;

        @include breakpoint.up(md) {
          color: colors.$link-base-default;
          background: colors.$element-grey-20;
          border: 1px solid colors.$border-base;
          flex-flow: row;
          align-items: flex-start;
          border-radius: 10px;
          padding: 8px;
        }

        @include breakpoint.up(xl) {
          width: fit-content;
        }
      }

      span {
        &:first-of-type {
          font-weight: 700;

          @include breakpoint.up(md) {
            font-weight: 400;
            text-transform: lowercase;

            &::after {
              content: " ";
              white-space: pre;
            }
          }

          @include breakpoint.up(xl) {
            display: none;
          }
        }
      }
    }

    .price {
      @include display.flex-col();
      align-items: flex-end;
      grid-area: price;

      section {
        @include display.flex-col();
        align-items: center;

        @include breakpoint.up(xl) {
          align-items: flex-start;
        }
      }

      span {
        &:first-of-type {
          font-size: 24px;
          font-weight: 700;
          line-height: 34px;
          color: #005e7b;
        }
        &:last-of-type {
          @include breakpoint.up(xl) {
            display: none;
          }
        }
      }
    }

    .cta {
      grid-area: cta;
      margin-top: 16px;
      justify-content: flex-end;
      align-items: flex-end;

      .link-element.button {
        @include display.flex-row-center();
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include breakpoint.up(md) {
          max-width: 75%;
        }

        @include breakpoint.up(xl) {
          width: unset;
          max-width: unset;
          align-items: center;
        }
      }
    }
  }
}
</style>
