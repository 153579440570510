<template>
  <div
    class="panel"
    :class="panel.type"
  >
    <slot name="panelTitle">
      <div
        v-if="panel.title"
        class="panel-title"
      >
        <span class="title">{{ panel.title }}</span>
        <tooltip
          v-if="panel.tooltip"
          :tooltip="panel.tooltip"
        />
      </div>
    </slot>
    <slot name="panelInput">
      <!-- To be filled with the input(s) of this panel -->
    </slot>
  </div>
</template>

<script>
import Tooltip from '../../product-info/ToolTip.vue';

export default {
    components: {
        Tooltip,
    },
    props: ['panel', 'value', 'analyticsName'],
    data() {
        return {
            tooltipExpanded: false,
        };
    },
};
</script>

<style lang="scss">
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/colors' as colors;
@use 'assets/scss/_helpers/typography'as typography;
@use 'assets/scss/_variables/typographies'as typographies;

.panel {
  .panel-title {
    padding: 12px 0;
    position: relative;
  }

  .hover-area {
    display: inline-flex;
  }

  .title {
    @include typography.font(typographies.$headings, h5);
  }

  .tooltip {
    @include icon.color(colors.$text-subtle);
    @include typography.font(typographies.$headings, subtitle-body);

    .popup {
      position: relative;
      margin-left: -24px;
      margin-right: -24px;
      border-radius: 0;

      @include breakpoint.up(xl) {
        position: absolute;
        width: 240px;
        margin: 16px auto;
        border-radius: 15px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
