<template>
  <panel :panel="panel" :slug="panel.slug" :value="value"  :comparer-name="analyticsName">
    <template #panelTitle> </template>

    <template #panelInput>
      <div class="radio-buttons">
        <BigRadioInput  v-for="option in panel.options" v-bind:key="panel.slug"
                       :title="option.label" v-model="checked" :value="option.value" :name="panel.slug"
                       :id="`${panel.slug}-${option.value}`"
        ></BigRadioInput>
      </div>
    </template>
  </panel>
</template>

<style lang="scss">

.panel.LargeRadio {
  .radio-buttons {
    display: grid;
    gap: 8px;
  }
}
</style>


<script>
import Panel from "./Panel.vue";
import BigRadioInput from 'vue-elements/input/BigRadioInput.vue';

export default {
  components: { BigRadioInput, Panel },
  props: ["panel", "value","analyticsName"],
  data: function () {
    return {
      checked: this.value,
    };
  },
  watch: {
    checked: {
      handler(checked) {
        this.$emit("value",  { slug: this.panel.slug, value: checked});
      },
    },
    value: {
      handler(value) {
        this.checked = value;
      },
    },
  },
};
</script>
