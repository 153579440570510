<template>
  <div class="topbar" :class="this.type">
    <panel-group
      :panels="panels"
      v-bind:selection="this.selection"
      :analyticsName="this.analyticsName"
      @filter="updateFilter"
    >
    </panel-group>
  </div>
</template>

<style lang="scss">
@use 'assets/scss/_helpers/background'as background;
@use 'assets/scss/_helpers/breakpoint'as breakpoint;
@use 'assets/scss/_helpers/typography'as typography;
@use 'assets/scss/_helpers/z-index' as z-index;
@use 'assets/scss/_helpers/box' as box;
@use '../../../vue-elements/sidebar/styles/animations' as animation;

.flamingo-product-display {
  $heading-font: (
    xxs: (
      24px,
      140%,
      700,
    ),
    md: (
      32px,
      130%,
    ),
  );

  .topbar {
    &.mobile {
      @include background.light();

      border-radius: 15px;
      margin-bottom: 24px;

      @include breakpoint.up(md) {
        margin-bottom: 16px;
      }

      .panel-title {
        padding: 0;
      }
      .panel-group {
        padding: 16px;
      }
    }

    &.desktop {
      display: none;
      margin-bottom: 32px;

      @include breakpoint.up(xl) {
        display: revert;
      }

      .panel {
        @include background.light;

        padding: 24px;
        border-radius: 15px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
      }
      .panel-title {
        padding-top:0px;
      }
    }

    @include breakpoint.up(xl) {
      display: none;
    }

    .panel-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }

    .panel {
      .input-container {
        display: flex;
        justify-content: space-between;
      }

      .field .radio {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1),
          inset 0px -2px 0px rgba(0, 52, 81, 0.25);
      }

      .field .select select {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1),
          inset 0px -2px 0px rgba(0, 52, 81, 0.25);
      }
    }
  }
}
</style>

<script>
import Icon from "vue-elements/icon/Icon.vue";
import PanelGroup from "vue-elements/sidebar/PanelGroup.vue";

export default {
  components: {
    Icon,
    PanelGroup,
  },
  props: ["type", "panels", "selection", "analyticsName"],
  methods: {
    updateFilter(filter) {
      this.$emit("filter", filter);
    },
  },
};
</script>
