// app.js
import { createApp } from 'vue';

import Mixins from '../../templates/vue-elements/comparison-lib/mixins';
import flamingoCarModelDisplay from '../../templates/shared/flamingoCarModelDisplay/flamingoCarModelDisplay.vue';
import privateLeaseComparison from '../../templates/shared/privateLeaseComparer/privateLeaseComparer.vue';

export function createFlamingoProductDisplayApp(context) {
    const contextProperties = {
        productId: context.productId,
        config: context.config,
    };

    const app = createApp(
        flamingoCarModelDisplay,
        contextProperties,
    );
    app.mixin(Mixins());

    return app;
}

export function createPrivateLeaseComparerApp(props) {
    const app = createApp(
        privateLeaseComparison,
        props,
    );

    app.mixin(Mixins());

    return app;
}
