<template>
  <panel
    :panel="panel"
    :slug="panel.slug"
    :value="value"
    :comparer-name="analyticsName"
  >
    <template #panelTitle />
    <template #panelInput>
      <SelectInput
        v-model="selected"
        type="button"
        :options="panel.options"
        :name="panel.slug"
      />
    </template>
  </panel>
</template>

<script>
import SelectInput from 'vue-elements/input/SelectInput.vue';
import Panel from './Panel.vue';

export default {
    components: {
        SelectInput,
        Panel,
    },
    props: ['panel', 'value', 'analyticsName'],
    data() {
        return {
            selected: this.value,
        };
    },
    watch: {
        selected: {
            handler(selected) {
                this.$emit('value', {
                    slug: this.panel.slug,
                    value: selected,
                });
            },
        },
        value: {
            handler(value) {
                this.selected = value;
            },
        },
    },
};
</script>

<style lang="scss">
@use '../styles/_helpers' as *;
@use 'assets/scss/_variables/colors' as colors;

.panel.Dropdown {
  .select {
    width: 100%;
    height: auto !important;

    select {
      @include panel-input-box();
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), inset 0px -2px 0px rgba(0, 52, 81, 0.25);
      padding-right: 30px;
    }

    &::after {
      content: "";
      border-color: colors.$all-orange-60 !important;
      width: 14px !important;
      height: 14px !important;
      border-width: 4px !important;
    }
  }
}
</style>
