<template>
  <div class="pagination-bar" role="button" :class="{ loading: loading }">
    <icon
      v-if="loading"
      icon="x-fa-spinner-third"
      size="m"
      class="spinner"
    ></icon>
    <icon v-else icon="x-fa-plus-circle" size="m" class="plus"></icon>
    <span class="label">
      Toon volgende {{ carsPerPage }} van {{ totalCars }}
    </span>
  </div>
</template>

<script>
import Icon from "vue-elements/icon/Icon.vue";

export default {
  components: { Icon },
  props: ["loading", "carsPerPage", "totalCars"],
};
</script>

<style lang="scss" scoped>
@use 'assets/scss/_helpers/display'as display;
@use 'assets/scss/_helpers/box' as box;
@use 'assets/scss/_helpers/loader' as loader;
@use 'assets/scss/_helpers/border' as border;
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/colors' as colors;

.pagination-bar {
  @include border.radius(xl);
  @include display.flex-row-center;
  @include box.default;
  @include icon.color(colors.$link-base-default);

  color: colors.$link-base-default;
  width: 100%;
  padding: 18px;
  font-weight: 700;
  line-height: 100%;

  @include breakpoint.up(md) {
    grid-column: 1 / span 2;
  }

  .icon-element {
    transition: all ease-out 0.15s;
    margin-right: 12px;
  }

  &:hover {
    @include box.shadow-small;

    .icon-element {
      transform: scale(1.05);
    }
  }

  .spinner {
    @include loader.spinner();
  }

  &.loading {
    .spinner {
      @include loader.play;
    }
  }
}
</style>
