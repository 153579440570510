<template>
  <panel
      :panel="panel"
      :slug="panel.slug"
      :value="value"
      :comparer-name="analyticsName"
  >
    <template #panelTitle></template>
    <template v-slot:panelInput>
      <div class="radio-buttons">
        <BigRadioInput type='no-check' v-for="option in panel.options" v-bind:key="panel.slug"
                       :title="option.label" v-model="checked" :value="option.value" :name="panel.slug"
                       :id="`${panel.slug}-${option.value}`"
        ></BigRadioInput>
      </div>
    </template>
  </panel>
</template>
<script setup>
import Panel from './Panel.vue';
import BigRadioInput from 'vue-elements/input/BigRadioInput.vue';
import { computed } from 'vue';

const emit = defineEmits(['value']);

const props = defineProps({
  'panel': Object,
  'value': null,
  'analyticsName': String,
});

const checked = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('value', {
      slug: props.panel.slug,
      value: value
    });
  }
});

</script>

<style lang="scss">
@use '../styles/helpers' as *;

.panel.InlineRadio {

  .radio-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }
}
</style>
