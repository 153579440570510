<template>
  <div class="element sticky-button">
    <span class="link button primary" role="button" @click="closeSideBar()">
      <span class="label">Naar overzicht</span>
      <icon icon="arrow-right-long-solid"></icon>
    </span>
  </div>
</template>

<style lang="scss">
@use '../styles/helpers' as *;
@use 'assets/scss/_helpers/background' as background;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_helpers/border' as border;
@use 'assets/scss/_variables/colors' as colors;

@import "templates/elements/link/button/_shared/link";

.sticky-button {
  @include escape-sidebar();
  @include background.light;
  @include border.top(colors.$border-base);

  padding: 16px;
  position: sticky;
  top: 100%;

  @include breakpoint.up(xl) {
    display: none;
  }

  .link {
    @extend %button;
    @extend %button--primary;

    transition: none;
    justify-content: space-between;
  }
}

.is-loading .sticky-button .link {
  animation: load-button 1.4s cubic-bezier(0.34, 0.91, 0.61, 0.13) forwards;
  background: linear-gradient(
    90deg,
    rgba(255, 134, 22, 1) 0%,
    rgba(255, 166, 0, 1) 50%,
    rgba(255, 134, 22, 1) 51%,
    rgba(255, 166, 0, 1) 100%
  );
  background-size: 200% 100%;
}

@keyframes load-button {
  from {
    background-position: right;
  }

  to {
    background-position: left;
  }
}
</style>

<script>
import Icon from "vue-elements/icon/Icon.vue";

export default {
  components: {
    Icon,
  },
  methods: {
    closeSideBar() {
      this.$emit("close");
    },
  },
};
</script>
