<template>
  <div
    class="simple-icon-element"
    :class="className"
    @click="$emit('click', $event)"
  >
    <div class="icon-container skeleton">
      <icon :icon="icon" size="m"></icon>
    </div>

    <div class="label skeleton">
      <slot name="label"></slot>
    </div>
  </div>
</template>
<style lang="scss">
@use 'assets/scss/_helpers/display' as display;
@use 'assets/scss/_helpers/background' as background;
@use 'assets/scss/_helpers/border' as border;
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_variables/colors' as colors;
@use 'assets/scss/_helpers/typography' as typography;
$label-font: (14px, 14px, 500);

.simple-icon-element {
  @include typography.font($label-font);

  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .label {
    display: inline-flex;
  }

  .icon-container {
    @include display.flex-row-center;
    @include border.radius;
    @include background.light;
    @include icon.color(colors.$link-base-default);

    padding: 8px;
    margin-right: 8px;
  }
}
</style>
<script>
import Icon from "../icon/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: ["icon", "className"],
};
</script>
